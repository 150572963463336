<template>
  <section class="section">
    <div class="container has-text-centered">
      <div class="column is-4 is-offset-4">
        <br />
        <div class="box box-shadow-lift">
          <form class="new_user" id="new_user" action="/login" accept-charset="UTF-8" method="post">
            <input name="utf8" type="hidden" value="&#x2713;" />
            <input
              type="hidden"
              name="authenticity_token"
              value="LEia3kurxojfkolWzOoqLHfJ/0VFltL+cfGv/gms50VkmZodH6SYTWj8SPm8ed9TgMqDmbM4hsUbe5vbV37T1g=="
            />
            <h3 class="title">
              Oh, hi there
              <span class="wave">🥱</span>
            </h3>
            <p class="subtitle">Come on in!</p>

            <div class="field">
              <div class="control has-icons-left">
                <input
                  autofocus="autofocus"
                  placeholder="Email address"
                  class="input"
                  type="email"
                  v-model="email"
                  value
                  name="user[email]"
                  id="user_email"
                />
                <span class="icon is-small is-left">
                  <i class="fal fa-envelope"></i>
                </span>
              </div>
              <p class="help is-danger" v-if="!validEmail">This email is invalid</p>
            </div>

            <div class="field">
              <div class="control has-icons-left">
                <input
                  autocomplete="off"
                  placeholder="Password"
                  class="input"
                  v-model="password"
                  type="password"
                  name="user[password]"
                  id="user_password"
                />
                <span class="icon is-small is-left">
                  <i class="fal fa-lock-alt"></i>
                </span>
              </div>
              <p
                class="help is-danger"
                v-if="!validPassword"
              >This password invalid minimum 8, must have uppercase, lowercase and digits</p>
            </div>
            <b-button
              type="submit"
              name="commit"
              @click="login"
              value="Log in"
              :disabled="!isRequired"
              class="button is-block is-medium is-link is-fullwidth is-size-6"
              data-disable-with="Log in"
            >Log in</b-button>
            <hr />
            <p style="margin-bottom: 9px">Don't have an account yet?</p>
            <a
              href="/signup"
              class="button is-block is-warning is-medium is-fullwidth is-size-6"
            >Sign up for free</a>
            <hr />
            <p>
              <a class="has-text-dark" href="/resetpass">Forgot your password?</a>
              <br />
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { passwordValidate } from "@/utils/password";
export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  created: function() {
    if (this.user !== null && this.user !== undefined) {
      this.$router.push("/");
    }
  },
  computed: {
    validEmail() {
      if (this.email == "") {
        return true;
      }
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    validPassword() {
      if (this.password == "") {
        return true;
      }
      return passwordValidate(this.password);
    },
    isRequired() {
      if (
        this.email == "" ||
        this.password == "" ||
        !this.validEmail ||
        !this.validPassword
      ) {
        return false;
      }
      return true;
    },
    user() {
      return this.$store.getters.user;
    },
    errors() {
      return this.$store.getters.errors;
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/");
      }
    }
  },

  methods: {
    login() {
      console.log(this.email, this.password);
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password
      });
    }
    // validEmail: function(email) {
    //   var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   return re.test(email);
    // }
  }
};
</script>