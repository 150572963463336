<template>
  <section class="section">
    <app-notice :text="errors" v-if="error" type="is-danger"></app-notice>
    <app-notice
      text="Please check your email to active your account"
      v-if="success"
      type="is-primary"
    ></app-notice>
    <div class="container has-text-centered">
      <br />
      <div class="column is-4 is-offset-4">
        <div class="box box-shadow-lift">
          <form class="new_user" id="new_user" action="/" accept-charset="UTF-8" method="post">
            <input name="utf8" type="hidden" value="&#x2713;" />
            <input
              type="hidden"
              name="authenticity_token"
              value="8zgB7M0WQcjtFsLvu8ksjFrOM4AlUznFrGFjjt8MZqk/nh1f2EcZARlutbq6+Eq9HtE1phQPoqBZQPFPlp5mbQ=="
            />

            <h3 class="title">
              Hello
              <span class="wave">👋</span>
            </h3>

            <div id="signup-email">
              <hr />
              <div class="field">
                <div class="control has-icons-left">
                  <input
                    autofocus="autofocus"
                    placeholder="Your name will do"
                    class="input"
                    v-model="username"
                    type="text"
                    name="user[name]"
                    id="user_name"
                  />
                  <span class="icon is-small is-left">
                    <i class="fal fa-signature"></i>
                  </span>
                </div>

                <p class="help is-danger" v-if="!validUsername">User name should be longer than 5</p>
              </div>

              <div class="field">
                <div class="control has-icons-left">
                  <input
                    placeholder="Your email address here"
                    class="input"
                    type="email"
                    v-model="email"
                    value
                    name="user[email]"
                    id="user_email"
                  />
                  <span class="icon is-small is-left">
                    <i class="fal fa-envelope"></i>
                  </span>
                </div>
                <p class="help is-danger" v-if="!validEmail">This email is invalid</p>
              </div>

              <div class="field">
                <div class="control has-icons-left">
                  <input
                    autocomplete="off"
                    placeholder="Generate a strong password"
                    class="input"
                    type="password"
                    v-model="password"
                    name="user[password]"
                    id="user_password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fal fa-lock-alt"></i>
                  </span>
                </div>
                <p
                  class="help is-danger"
                  v-if="!validPassword"
                >This password invalid minimum 8, must have uppercase, lowercase and digits</p>
              </div>
              <div class="field">
                <div class="control has-icons-left">
                  <input
                    autocomplete="off"
                    placeholder="Repeat the password"
                    class="input"
                    type="password"
                    v-model="repeat_password"
                    name="user[password]"
                    id="repeat_user_password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fal fa-lock-alt"></i>
                  </span>
                </div>
                <p class="help is-danger" v-if="!passwordMatch">Password should match</p>
              </div>
              <p></p>

              <br />

              <b-button
                type="submit"
                name="commit"
                @click="signup"
                value="Let&#39;s start!"
                :disabled="!isRequired"
                class="button is-medium is-link is-fullwidth is-size-6"
                data-disable-with="Let&#39;s start!"
              >Sign Up</b-button>
            </div>
          </form>
          <hr />
          <p>
            Already have an account?
            <a href="/login" class="has-text-link">Log in</a>
          </p>
          <br />
          <p>
            <a class="has-text-grey" href="/terms-of-service">Terms</a> &nbsp;·&nbsp;
            <a class="has-text-grey" href="/privacy-policy">Privacy</a> &nbsp;·&nbsp;
            <a class="has-text-grey" href="/cookies-policy">Cookies</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { passwordValidate } from "@/utils/password";
export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
      repeat_password: "",
      success: false
    };
  },
  created: function() {
    // if (this.user !== null && this.user !== undefined) {
    //   this.$router.push("/");
    // }
  },
  computed: {
    validUsername() {
      if (this.username == "") {
        return true;
      }
      if (this.username.trim().length > 4) {
        return true;
      }
      return false;
    },
    validEmail() {
      if (this.email == "") {
        return true;
      }
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    passwordMatch() {
      if (this.password && this.repeat_password) {
        if (this.password == this.repeat_password) {
          return true;
        }
      }
      return false;
    },
    validPassword() {
      if (this.password == "") {
        return true;
      }
      return passwordValidate(this.password);
    },
    isRequired() {
      if (
        this.email == "" ||
        this.password == "" ||
        !this.validEmail ||
        !this.validPassword ||
        !this.passwordMatch
      ) {
        return false;
      }
      return true;
    },
    user() {
      console.log("user is ", this.$store.getters.user);
      return this.$store.getters.user;
    },
    errors() {
      return this.$store.getters.errors;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/");
      }
    },
    error(value) {
      console.log("error", value);
    }
  },

  methods: {
    clear() {
      this.username = "";
      this.email = "";
      this.password = "";
      this.repeat_password = "";
      console.log("clear");
    },
    signup() {
      console.log(this.username, this.email, this.password);
      this.success = false;
      this.$store.dispatch("signup", {
        user_name: this.username,
        email: this.email,
        password: this.password
      });
      var that = this;
      setTimeout(() => {
        if (!that.error) {
          console.log("error is false", that.error);
          that.success = true;
        }
      }, 1000);
      this.clear();
    }
    // validEmail: function(email) {
    //   var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   return re.test(email);
    // }
  }
};
</script>