export default {
    state: {
        loading: true,
        error: false,
        errors: null
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.errors = payload
            state.error = true
        },
        clearError(state) {
            state.error = false
            state.errors = null
        }
    },
    actions: {
        clearError({ commit }) {
            commit('clearError')
        },
        setError({ commit }, payload) {
            commit('setError', payload)
        },
        setLoading({ commit }, payload) {
            commit('setLoading', payload)
        }
    },
    getters: {
        loading(state) {
            return state.loading
        },
        errors(state) {
            return state.errors
        },
        error(state) {
            return state.error
        }
    }
}
