<template>
  <section>
    <b-notification
      :active.sync="isActive"
      aria-close-label="Close notification"
      :type="type"
    >{{text}}</b-notification>
  </section>
</template>
<script>
export default {
  props: ["text", "type"],
  data() {
    return {
      isActive: true
    };
  },
  created: function() {
    var that = this;
    console.log("type", this.type);
    setTimeout(function() {
      that.isActive = false;
      console.log("created", that.isActive);
      that.$store.dispatch("clearError");
    }, 3000);
  },
  watch: {
    isActive() {
      var that = this;
      setTimeout(function() {
        if (that.isActive == true) {
          that.isActive = false;
          console.log("watch", that.isActive);
          that.$store.dispatch("clearError");
        }
      }, 3000);
    }
  }
};
</script>
<style scoped>
.notification.is-danger {
  position: fixed;
  z-index: 30;
}
</style>