<template>
  <div>
    <Section></Section>
    <Price></Price>
  </div>
</template>

<script>
import Section from "@/components/Section.vue";
import Price from "@/components/Price.vue";
export default {
  name: "HelloWorld",
  components: {
    Section,
    Price
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->