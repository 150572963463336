<template>
  <div>
    <app-notice :text="errors" v-if="error" type="is-danger"></app-notice>
    <section id="search-box" class="section intro has-text-centered">
      <h1 class="title is-2">Banquet Halls and Wedding Reception Venues</h1>
      <div>
        <section class="section">
          <div class="container">
            <b-field grouped position="is-centered">
              <input
                type="search"
                icon="magnify"
                icon-clickable
                v-model="searchWord"
                @keyup.enter="search"
                placeholder="Search Rss feed"
                class="input is-medium"
              />
              <span class="icon is-left">
                <i class="mdi mdi-magnify mdi-24px"></i>
              </span>
              <!---->
              <!---->

              <p class="control">
                <b-button
                  class="button is-primary is-medium"
                  @click="search"
                  ref="searchButton"
                >Search</b-button>
              </p>
            </b-field>
          </div>
        </section>
        <p>
          <a>review our banquet hall directory organized by state</a>
        </p>
      </div>
    </section>
    <div v-if="!loading && !loadingSubscribe">
      <div
        class="columns is-vcentered is-multiline is-centered"
        v-for="feed in feeds"
        v-bind="{ [`xs12`]: true }"
        :key="feed.shortid"
      >
        <Card :feed="feed" :user="user"></Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card.vue";
export default {
  components: {
    Card
  },
  data: () => ({
    searchWord: "",
    offset: 0,
    limit: 10
  }),
  created: function() {
    const q = this.$route.query.q;
    let query = "";
    if (q) {
      this.searchWord = q;
      query = `q=${q}`;
    }
    this.$store.dispatch("loadFeeds", query);
    window.addEventListener("scroll", this.handleScroll);
    // window.addEventListener('beforeunload', this.refresh)
    if (this.user) {
      this.$store.dispatch("loadMyFeeds");
    }
  },
  watch: {
    searchWord() {
      console.log(this.searchWord);
    },
    subEvent() {
      console.log(this.subEvent);
    }
  },
  computed: {
    errors() {
      return this.$store.getters.errors;
    },
    error() {
      return this.$store.getters.error;
    },
    featuredJobs() {
      return this.$store.getters.featuredJobs;
    },
    user() {
      return this.$store.getters.user;
    },
    feeds() {
      return this.$store.getters.feeds;
    },
    loading() {
      return this.$store.getters.loading;
    },
    loadingSubscribe() {
      return this.$store.getters.loadingSubscribe;
    }
  },
  methods: {
    search() {
      const query = `q=${this.searchWord}`;
      console.log(`query is ${query}`);
      this.$store.dispatch("loadFeeds", query);
    },
    onLoadJob(slug) {
      this.$router.push("/job/" + slug);
    },
    nextScroll() {
      return this.$store.getters.nextScroll;
    },
    scroll(count) {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        console.log(count);

        if (bottomOfWindow) {
          var query;
          this.offset += 10;

          query = `offset=${this.offset}&limit=${this.limit}`;

          if (this.searchWord !== "") {
            query = `q=${this.searchWord}&${query}`;
          }

          this.$store.dispatch("loadFeeds", query);
          console.log("bottomOfWindow");
        }
      };
    }
  },
  mounted() {
    this.scroll("scoll");
  }
};
</script>