<template>
  <section class="section is-medium">
    <div class="container has-text-centered">
      <div class="column is-4 is-offset-4">
        <div class="box">
          <form
            class="new_user"
            id="new_user"
            action="/password"
            accept-charset="UTF-8"
            method="post"
          >
            <input name="utf8" type="hidden" value="&#x2713;" />
            <!-- <input
              type="hidden"
              name="authenticity_token"
              value="A5xVIH5Iasmg5oGwNGTatwMQDtoEP0a8WYS6i2Uq0SRqJIHt8RviXDo+VK/Y9xG2htygams7DPSuJasGMqlrRw=="
            />-->

            <h3 class="title">Teehee 🙊</h3>
            <p class="subtitle">Let's get a new one for you</p>
            <vue-recaptcha
              sitekey="6LfwS-oUAAAAAHBfo7t7w9W4XqIO3ruVhRu879O7"
              ref="invisibleRecaptcha"
              @verify="onVerify"
              @expired="onExpired"
              @error="recaptchaError"
              :loadRecaptchaScript="true"
              size="invisible"
            ></vue-recaptcha>
            <div class="field">
              <div class="control has-icons-left">
                <input
                  autofocus="autofocus"
                  placeholder="Email address"
                  class="input"
                  type="email"
                  value
                  name="user[email]"
                  id="user_email"
                />
                <span class="icon is-small is-left">
                  <i class="fal fa-envelope"></i>
                </span>
              </div>
            </div>

            <div class="column margin-middle">
              <!-- <div data-sitekey="6Lc3ZdIUAAAAAD9KdU-Be8d9eDuF6KOgsR0wgCyG" class="g-recaptcha"></div> -->
              <p></p>
            </div>

            <b-button
              type="submit"
              name="commit"
              @click="onSubmit"
              value="Reset Password"
              class="button is-outlined is-block is-dark is-medium is-fullwidth is-size-6"
              data-disable-with="Reset Password"
            >Reset Password</b-button>
          </form>
          <hr />
          <p>
            Already have an account?
            <a href="/login" class="has-text-link">Log in</a>
          </p>
          <br />
          <p>
            <a class="has-text-grey" href="/terms-of-service">Terms</a> &nbsp;·&nbsp;
            <a class="has-text-grey" href="/privacy-policy">Privacy</a> &nbsp;·&nbsp;
            <a class="has-text-grey" href="/cookies-policy">Cookies</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  components: { VueRecaptcha },
  data() {
    return {
      email: "",
      password: "",
      repeat_password: ""
    };
  },
  methods: {
    onSubmit: function() {
      this.$refs.invisibleRecaptcha.execute();
      console.log("onSubmit");
    },
    onVerify: function(response) {
      console.log("Verify: " + response);
    },
    onExpired: function() {
      console.log("Expired");
    },
    recaptchaError: function(error) {
      console.log(error);
    }
  }
};
</script>