import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
import Signup from '@/components/Signup'
import ResetPassword from '@/components/ResetPassword'
import Feeds from '@/components/Feeds'
import Feed from '@/components/Feed'
import Active from '@/components/Active'
import Myfeeds from '@/components/Myfeeds'
import Error404 from '@/components/Error/404'
// import AuthGuard from './auth-guard'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/login',
            name: 'Login',
            // props: true,
            component: Login
        },
        {
            path: '/signup',
            name: 'Signup',
            // props: true,
            component: Signup
        },
        {
            path: '/resetpass',
            name: 'ResetPassword',
            // props: true,
            component: ResetPassword
        },
        {
            path: '/feeds',
            name: 'Feeds',
            // props: true,
            component: Feeds
        },
        {
            path: '/feed/:feed',
            name: 'Feed',
            props: true,
            component: Feed
        },
        {
            path: '/active/:token',
            name: 'Active',
            props: true,
            component: Active
        },
        {
            path: '/myfeeds',
            name: 'Myfeeds',
            props: true,
            component: Myfeeds
        },
        // {
        //   path: '/client/:keyword',
        //   name: 'Client',
        //   props: true,
        //   component: Client
        // },
        // {
        //   path: '/tool/:keyword',
        //   name: 'Tools',
        //   props: true,
        //   component: Tool
        // },
        // {
        //   path: '/skill/:keyword',
        //   name: 'Skills',
        //   props: true,
        //   component: Skill
        // },
        // {
        //   path: '/language/:keyword',
        //   name: 'Languages',
        //   props: true,
        //   component: Language
        // },
        // // {
        // //   path: '/jobtype/:keyword',
        // //   name: 'Type',
        // //   props: true,
        // //   component: Type
        // // },
        // {
        //   path: '/location/:keyword',
        //   name: 'Location',
        //   props: true,
        //   component: Location
        // },
        // // {
        // //   path: '/signup',
        // //   name: 'Signup',
        // //   component: Signup,
        // //   beforeEnter: AuthGuard
        // // },
        // // {
        // //   path: '/signin',
        // //   name: 'Signin',
        // //   component: Signin
        // // },
        // {
        //   path: '/user/active/:token',
        //   name: 'active',
        //   props: true,
        //   component: Active
        // },
        {
            path: '/404',
            name: 'error404',
            component: Error404
        },
        {
            path: '*',
            name: 'error404*',
            component: Error404
        }
    ],
    base: __dirname,
    // mode: "hash",
    mode: 'history'
})
