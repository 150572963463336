import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import feed from './feed'
import user from './user'
import shared from './shared'
Vue.use(Vuex)
export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        feed: feed,
        user: user,
        shared: shared
    }
})
