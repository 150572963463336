// import router from '@/router'
import { HTTP } from '@/components/CommonJs/axios'
export default {
    state: {
        loadedFeeds: [],
        nextScroll: null,
        myFeeds: [],
        subscribedFeeds: null,
        loadingSubscribe: true,
        isSubscribed: {},
        feed: {},
        unauthFeed: {},
    },

    mutations: {
        setLoadMoreFeeds(state, payload) {
            state.loadedFeeds = state.loadedFeeds.concat(payload)
        },
        setInitFeeds(state, payload) {
            state.loadedFeeds = payload
        },
        loadMyFeeds(state, payload) {
            state.myFeeds = payload
        },
        setFeed(state, payload) {
            state.feed[payload.shortid] = payload
        },
        setUnAuthorizedFeed(state, payload) {
            state.unauthFeed[payload.shortid] = payload
        },
        setLoadingSubscribe(state, payload) {
            state.loadingSubscribe = payload
        },
        addSubscribedFeeds(state, payload) {
            if (state.subscribedFeeds == null) {
                state.subscribedFeeds = new Set()
            }
            console.log("state.subscribedFeeds payload", state.subscribedFeeds, payload)
            state.subscribedFeeds.add(payload)
            console.log("state.subscribedFeeds payload", state.subscribedFeeds, payload)
        },
        delSubscribedFeeds(state, payload) {
            if (state.subscribedFeeds !== null && payload !== null) {
                console.log("before delete", state.subscribedFeeds, payload)
                state.subscribedFeeds.delete(payload)
                console.log("after delete", state.subscribedFeeds, payload)
            }
        },
        setSubscribedFeeds(state, payload) {
            if (state.subscribedFeeds == null) {
                state.subscribedFeeds = new Set()
            }
            for (let i in payload) {
                state.subscribedFeeds.add(payload[i])
            }
        },
        clearSubscribedFeeds(state) {
            state.subscribedFeeds = null
        },
        setNextScroll(state, payload) {
            state.nextScroll = payload
        },
    },
    actions: {
        getFeed({ commit, getters }, payload) {
            commit('setLoading', true)
            HTTP.get(`feed/${payload}`, {
                headers: {
                    'Authorization': `Bearer ${getters.user.token}`
                }
            })
                .then(
                    response => {
                        commit('setLoading', false)
                        commit('setFeed', response.data.data)
                    }
                ).catch(error => {
                    commit('setLoading', false)
                    // commit('setError', error.response)
                    console.log(error.response)
                })
        },
        getUnAuthorizedFeed({ commit }, payload) {
            commit('setLoading', true)
            HTTP.get(`feed/unauth/${payload}`)
                .then(response => {
                    commit('setLoading', false)
                    commit('setUnAuthorizedFeed', response.data.data)
                }).catch(error => {
                    commit('setLoading', false)
                    // commit('setError', error.response)
                    console.log(error.response)
                })
        },
        addSubscribedFeeds({ commit }, payload) {
            commit("addSubscribedFeeds", payload)
        },
        delSubscribedFeeds({ commit }, payload) {
            commit("delSubscribedFeeds", payload)
        },
        loadMyFeeds({ commit, getters }) {
            commit('setLoading', true)

            commit('clearSubscribedFeeds')
            if (getters.user) {
                commit('setLoadingSubscribe', true)
            } else {
                commit('setLoadingSubscribe', false)
            }

            HTTP.get('user/feeds', {
                headers: {
                    'Authorization': `Bearer ${getters.user.token}`
                }
            })
                .then(
                    response => {
                        commit('setLoading', false)
                        commit('setLoadingSubscribe', false)

                        commit('loadMyFeeds', response.data.data)
                        var subscribedFeeds = []
                        for (var i in response.data.data) {
                            subscribedFeeds.push(response.data.data[i].shortid)
                        }
                        commit('setSubscribedFeeds', subscribedFeeds)
                    }
                ).catch(error => {
                    commit('setLoading', false)
                    commit('setError', error.response.data.msg)
                    commit('setLoadingSubscribe', false)
                })
        },
        unSubscribe({ commit, getters }, payload) {
            commit('setLoading', true)
            HTTP.delete(`sub/${payload}`, {
                headers: {
                    'Authorization': `Bearer ${getters.user.token}`
                }
            })
                .then(() => {
                    commit("delSubscribedFeeds", payload)
                    commit('setLoading', false)
                }).catch(error => {
                    commit('setError', error.response.data.msg)
                    commit('setLoading', false)
                })
        },
        subscribe({ commit, getters }, payload) {
            commit('setLoading', true)
            HTTP.post('sub/', { "subscription": payload }, {
                headers: {
                    'Authorization': `Bearer ${getters.user.token}`
                }
            })
                .then(() => {
                    commit("addSubscribedFeeds", payload)
                    commit('setLoading', false)
                }).catch(error => {
                    commit('setError', error.response.data.msg)
                    console.log('error', error.response.data.msg)
                    commit('setLoading', false)
                })
        },
        loadFeeds({ commit }, payload) {
            if (payload.includes("offset") && payload.includes("limit")) {
                commit('setLoading', false)
            } else {
                commit('setLoading', true)
            }

            HTTP.get(`feed?${payload}`)
                .then(response => {
                    commit('setLoading', false)
                    if (payload.includes("offset") && payload.includes("limit")) {
                        commit('setLoadMoreFeeds', response.data.data)
                    } else {
                        commit('setInitFeeds', response.data.data)
                    }
                    // console.log(response.data)
                }).catch(
                    error => {
                        commit('setLoading', false)
                        commit('setError', error.response.data.msg)
                    }
                )
        },
    },
    getters: {
        feeds(state) {
            return state.loadedFeeds
        },
        myfeeds(state) {
            return state.myFeeds
        },
        mysubed(state) {
            console.log("getters mysubed", state.subscribedFeeds)
            return state.subscribedFeeds
        },
        unauthFeed(state) {
            return state.unauthFeed
        },
        feed(state) {
            return state.feed
        },
        loadingSubscribe(state) {
            return state.loadingSubscribe
        }
    }
}
