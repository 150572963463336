<template>
  <div v-if="!loading">
    <div class="container hero-block">
      <div class="columns hero-section mt-5">
        <div class="column is-10">
          <div class="columns is-vcentered">
            <div class="column is-5 text-center-m">
              <div class="is-primary">
                <h1 class="title">{{decode(feedInfo[feed].title)}}</h1>
                <!-- <p class="subtitle">{{decode(feedInfo[feed].description)}}.</p> -->
                <div class="buttons is-fullwidth">
                  <SubButton
                    @clicked="onClickSubscribe"
                    :isSub="isSubscribed()"
                    :feed="feedInfo[feed]"
                    v-if="user && !loading && isSubscribed() != null"
                  ></SubButton>

                  <div
                    class="control"
                    style="margin-bottom:0.5rem"
                    v-if="user && !loading && isSubscribed() != null"
                  >
                    <input class="input" type="text" :value=" showString" readonly />
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-7 position-relative">
              <picture>
                <p class="subtitle">{{decode(feedInfo[feed].description)}}.</p>
              </picture>
              <div class="position-absolute" id="how-it-works"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section v-if="!loading" class="section intro has-text-centered">
      <div
        v-for="(item) in feedInfo[feed].item"
        v-bind="{ [`xs12`]: true }"
        :key="item.link"
        class="columns is-vcentered is-centered"
      >
        <ItemCard :item="item"></ItemCard>
      </div>
    </section>
  </div>
</template>
<script>
import ItemCard from "@/components/ItemCard";
import SubButton from "@/components/SubButton.vue";
import { decodeHtmlEntity } from "@/utils/string";
export default {
  components: {
    ItemCard,
    SubButton
  },
  props: ["feed"],
  data: () => ({
    showHost: false,
    showString: "Please subscribe first to get rss feed"
  }),
  created: function() {
    if (this.user) {
      this.$store.dispatch("getFeed", this.feed);
      this.$store.dispatch("loadMyFeeds");
    } else {
      this.$store.dispatch("getUnAuthorizedFeed", this.feed);
    }

    // window.addEventListener("scroll", this.handleScroll);
    // // window.addEventListener('beforeunload', this.refresh)
    // this.$store.dispatch("loadMyFeeds");
  },
  computed: {
    errors() {
      return this.$store.getters.errors;
    },
    error() {
      return this.$store.getters.error;
    },
    user() {
      console.log("user", this.$store.getters.user);
      return this.$store.getters.user;
    },
    feedInfo() {
      if (this.user) {
        return this.$store.getters.feed;
      } else {
        return this.$store.getters.unauthFeed;
      }
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  methods: {
    isSubscribed() {
      console.log("mysubed computed is ", this.$store.getters.mysubed);
      if (this.$store.getters.mysubed == null) {
        return null;
      }
      if (this.$store.getters.mysubed.has) {
        return this.$store.getters.mysubed.has(this.feed);
      }
      return false;
    },
    getHost() {
      console.log(
        "this.isSubscribed && this.user && this.showHost",
        this.isSubscribed() && this.user && this.showHost
      );
      if (this.isSubscribed() && this.user && this.showHost) {
        return (
          process.env.VUE_APP_HOST +
          "/rss/" +
          this.feed +
          "?token=" +
          this.user.feed_token
        );
      }
      return "Please subscribe first to get rss feed";
    },
    decode(str) {
      return decodeHtmlEntity(str);
    },
    onClickSubscribe(event) {
      console.log("event is ", event);
      this.showHost = event;
      this.showString = this.getHost();
      console.log("onClickSubscribe", this.showHost, this.showString);
      if (event == false) {
        console.log("onClickSubscribe deleting");
        this.$store.dispatch("delSubscribedFeeds", this.feed);
      }
      console.log("isSubscribed is ", this.isSubscribed());
    }
  }
};
</script>