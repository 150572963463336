<template>
  <section>
    <button class="button is-success" @click="subscribe()" v-if="!isSubscribed">
      <span class="icon is-small">
        <i class="fas fa-check"></i>
      </span>
      <!-- <span>Subscribe</span> -->
    </button>
    <button class="button is-danger is-outlined" @click="unsubscribe()" v-if="isSubscribed">
      <!-- <span>Unsubscribe</span> -->
      <span class="icon is-small">
        <i class="fas fa-times"></i>
      </span>
    </button>
  </section>
</template>
<script>
export default {
  props: ["isSub", "feed"],
  data() {
    return {
      isSubscribed: false
    };
  },
  created() {
    if (typeof this.isSub !== "undefined") {
      this.isSubscribed = this.isSub;
    }
    // console.log("isSub", this.isSub);
    this.$emit("clicked", this.isSub);
    // console.log("feed", this.feed);
  },
  // watch: {
  //   isSubscribed(value) {
  //     console.log("isSubscribed", value);
  //   }
  // },
  methods: {
    subscribe() {
      this.$emit("clicked", true);
      // console.log("subscribe", this.feed);
      this.isSubscribed = true;
      // console.log("subscribe", this.feed);
      // console.log("subscribe shortid", this.feed);
      this.$store.dispatch("subscribe", this.feed.shortid);
      this.$store.dispatch("addSubscribedFeeds", this.feed.shortid);
    },
    unsubscribe() {
      this.$emit("clicked", false);

      this.isSubscribed = false;
      this.$store.dispatch("unSubscribe", this.feed.shortid);
      this.$store.dispatch("delSubscribedFeeds", this.feed.shortid);
      // console.log("unsubscribe", this.feed);
    }
  }
};
</script>