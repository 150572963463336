<template>
  <div class="is-5 tile is-parent">
    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img :src="item.image" :alt="item.title" />
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="is-1">By {{item.author}}</p>
          </div>
        </div>
        <p class="title">{{ decode(item.title) }}</p>
        <div class="content">{{ decode(item.excerpt)}}</div>
      </div>
      <footer class="card-footer">
        <p class="card-footer-item">
          <span>
            <a :href="item.link">Source</a>
          </span>
        </p>
        <p class="card-footer-item">
          <span>
            <time :datetime="item.pubDate">{{timeAgo(item.pubDate)}}</time>
          </span>
        </p>
      </footer>
    </div>
  </div>
</template>
<script>
import * as timeago from "timeago.js";
import { decodeHtmlEntity } from "@/utils/string";
export default {
  props: ["item"],
  methods: {
    timeAgo(time) {
      return timeago.format(time);
    },
    decode(str) {
      return decodeHtmlEntity(str);
    }
  }
};
</script>