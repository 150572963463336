<template>
  <b-navbar :fixed-top="true">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <template slot="start" v-if="homePage">
      <b-navbar-item href="/">Home</b-navbar-item>
      <b-navbar-item href="/feeds">Feeds</b-navbar-item>
      <b-navbar-item href="/myfeeds">My Feeds</b-navbar-item>
      <b-navbar-dropdown label="Info">
        <b-navbar-item href="#">About</b-navbar-item>
        <b-navbar-item href="#">Contact</b-navbar-item>
      </b-navbar-dropdown>
    </template>

    <template slot="end" v-if="homePage && !user">
      <b-navbar-item tag="div">
        <div class="buttons">
          <a class="button is-primary" href="/signup">
            <strong>Sign up</strong>
          </a>
          <a class="button is-light" href="/login">Log in</a>
        </div>
      </b-navbar-item>
    </template>
    <app-notice :text="errors" v-if="error" type="is-danger"></app-notice>
  </b-navbar>
</template>
<script>
export default {
  computed: {
    errors() {
      console.log("header errors", this.$store.getters.errors);
      return this.$store.getters.errors;
    },
    error() {
      return this.$store.getters.error;
    },
    user() {
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path == "/login" || this.$route.path == "/signup") {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
