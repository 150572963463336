import router from '@/router'
import { HTTP } from '@/components/CommonJs/axios'
export default {
    state: {
        user: null,
        verified: false,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setVerifyState(state, payload) {
            state.verified = payload
        },
    },
    actions: {
        active({ commit }, token) {
            commit('clearError')
            commit('setLoading', true)
            HTTP.get('user/active/' + token)
                .then((response) => {
                    console.log(response.data)
                    commit("setVerifyState", true)
                    commit('clearError')
                })
                .catch((error) => {
                    commit('setError', error.response.data.msg)
                    commit("setVerifyState", false)
                })
        },
        signup({ commit }, payload) {
            // commit('setLoading', true)
            commit('clearError')
            HTTP.post('user/info', payload)
                .then(
                    () => {
                        commit('setLoading', false)
                        commit('setUser', null)
                    }
                )
                .catch(
                    error => {
                        commit('setLoading', false)
                        commit('setError', error.response.data.msg)

                    }
                )
        },
        login({ commit }, payload) {
            // commit('setLoading', true)
            commit('clearError')
            HTTP.post('user/login', payload)
                .then(
                    response => {
                        commit('setLoading', false)
                        const newUser = {
                            token: response.data.data.token,
                            feed_token: response.data.data.feed_token
                        }
                        console.log("response data ", response.data.data.token)
                        commit('setUser', newUser)
                    }
                )
                .catch(
                    error => {
                        commit('setLoading', false)
                        commit('setError', error.response.data.msg)
                    }

                )
        },
        logout({ commit }) {
            // firebase.auth().signOut()
            commit('setUser', null)
            commit('clearBlogs')
            router.push('/')
        }
    },
    getters: {
        user(state) {
            return state.user
        },
        getVerifyState(state) {
            return state.verified
        },
    }
}
