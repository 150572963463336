<template>
  <div class="column is-10">
    <div class="card box card-shadow">
      <header class="card-header">
        <a :href="'/feed/' + feed.shortid">
          <p class="title is-4">{{ decode(feed.title) }}</p>
        </a>

        <p class="card-header-title">
          <a
            style="color:black"
            :href="'/feed/' + feed.shortid"
          >{{ decode(feed.description) | limit(80)}}</a>
        </p>

        <SubButton :isSub="mysubed" :feed="feed" v-if="user"></SubButton>
      </header>
      <div class="card-content has-text-left">
        <!-- <p class="title">“Hacker Noon - agile”</p> -->
        <ul
          v-for="item in feed.item.slice(0,5)"
          v-bind="{ [`xs12`]: true }"
          :key="randomKey(item.title)"
        >
          <li>* {{decode(item.title) | limit(80) }}...</li>
        </ul>
      </div>
      <footer class="card-footer">
        <p class="card-footer-item">
          <a style="color:black" :href="'/feeds?q=' + feed.domain">
            <span>{{feed.domain}}</span>
          </a>
        </p>
        <!-- <a :href="'http://localhost:1202/feeds/'+feed.shortid+'?token='+user.feed_token">rss</a> -->
        <p class="card-footer-item">
          <span>
            <p>Updated @ {{timeAgo(feed.lastBuildDate)}}</p>
          </span>
        </p>
      </footer>
    </div>
  </div>
</template>
<script>
import SubButton from "@/components/SubButton.vue";
import { decodeHtmlEntity } from "@/utils/string";
import * as timeago from "timeago.js";
export default {
  components: {
    SubButton
  },
  props: ["feed", "user"],
  filters: {
    limit: function(str, count) {
      if (str) {
        return str.slice(0, count);
      }
      return str;
    }
  },
  // created() {
  //   console.log("user", this.user);
  //   console.log("feed", this.feed);
  // },
  methods: {
    decode(str) {
      return decodeHtmlEntity(str);
    },
    timeAgo(time) {
      return timeago.format(time);
    },
    randomKey(str) {
      return str + Math.random();
    },
    test() {
      console.log("button");
    }
  },
  computed: {
    mysubed() {
      return this.$store.getters.mysubed.has(this.feed.shortid);
    }
  }
};
</script>