<template>
  <section class="section is-medium">
    <div class="container has-text-centered">
      <div class="column is-4 is-offset-4">
        <div class="box">
          <form
            class="new_user"
            id="new_user"
            action="/password"
            accept-charset="UTF-8"
            method="post"
          >
            <input name="utf8" type="hidden" value="&#x2713;" />
            <!-- <input
              type="hidden"
              name="authenticity_token"
              value="A5xVIH5Iasmg5oGwNGTatwMQDtoEP0a8WYS6i2Uq0SRqJIHt8RviXDo+VK/Y9xG2htygams7DPSuJasGMqlrRw=="
            />-->

            <h3 class="title">Welcome 🙊</h3>
            <p
              class="subtitle"
              v-if="verified"
            >You have been activated successfully, please try to login now</p>
            <p class="subtitle" v-if="!verified">You have been activated already or invalid token!</p>
            <div class="column margin-middle">
              <p></p>
            </div>
          </form>
          <hr />
          <p>
            Already have an account?
            <a href="/login" class="has-text-link">Log in</a>
          </p>
          <br />
          <p>
            <a class="has-text-grey" href="/terms-of-service">Terms</a> &nbsp;·&nbsp;
            <a class="has-text-grey" href="/privacy-policy">Privacy</a>
            &nbsp;·&nbsp;
            <a class="has-text-grey" href="/cookies-policy">Cookies</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["token"],
  created: function() {
    if (this.token) {
      this.$store.dispatch("active", this.token);
    }
  },
  computed: {
    verified() {
      return this.$store.getters.getVerifyState;
    }
  }
};
</script>